import React from 'react'
import PropTypes from 'prop-types'
import DateRangeFilter from 'components-v2/molecules/DateRangeFilter'

const DateTimeRangeFilter = ({ value, onChange }) => {
  const dateControlProps = {
    dateFormat: 'yyyy/MM/dd h:mm aa',
    fullWidth: true,
    showTimeSelect: true,
    todayButton: 'Today',
    portalId: 'datepicker-portal',
  }

  return (
    <DateRangeFilter
      datePickerProps={dateControlProps}
      preserveTextSpaceForFilter
      value={value}
      onChange={onChange}
    />
  )
}
DateTimeRangeFilter.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
}

export default DateTimeRangeFilter
