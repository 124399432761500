import React from 'react'
import PropTypes from 'prop-types'
import Input from 'components-v2/atoms/Input'
import { BaseField } from 'components-v2/molecules/FormField'

const NumberRangeFilter = ({ value, onChange }) => {
  const handleChange = (key, val) => {
    onChange({
      ...value,
      [key]: val.target.value,
    })
  }

  return (
    <>
      <BaseField label="Min">
        <Input
          type="number"
          value={value?.min || ''}
          onChange={(val) => handleChange('min', val)}
        />
      </BaseField>
      <BaseField label="Max">
        <Input
          type="number"
          value={value?.max || ''}
          onChange={(val) => handleChange('max', val)}
        />
      </BaseField>
    </>
  )
}

NumberRangeFilter.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
}

export default NumberRangeFilter
