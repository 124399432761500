import React from 'react'
import PropTypes from 'prop-types'
import { BaseField as BaseFormField } from '../FormField'
import DatePicker from '../DatePicker'

const DateRangeFilter = ({
  value,
  onChange,
  minDate,
  maxDate,
  startLabel,
  endLabel,
  datePickerProps,
  preserveTextSpaceForFilter,
  ...rest
}) => (
  <div {...rest}>
    <BaseFormField
      label={startLabel}
      preserveTextSpaceForFilter={preserveTextSpaceForFilter}
    >
      <DatePicker
        {...datePickerProps}
        minDate={minDate}
        maxDate={value.endDate || maxDate}
        value={value.startDate}
        onChange={(startDate) => onChange({ ...value, startDate })}
        fullWidth
      />
    </BaseFormField>
    <BaseFormField
      label={endLabel}
      preserveTextSpaceForFilter={preserveTextSpaceForFilter}
    >
      <DatePicker
        {...datePickerProps}
        minDate={value.startDate || minDate}
        maxDate={maxDate}
        value={value.endDate}
        onChange={(endDate) => onChange({ ...value, endDate })}
        fullWidth
      />
    </BaseFormField>
  </div>
)

DateRangeFilter.propTypes = {
  value: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  startLabel: PropTypes.string,
  endLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  datePickerProps: PropTypes.object,
  preserveTextSpaceForFilter: PropTypes.bool,
}

DateRangeFilter.defaultProps = {
  value: {},
  startLabel: 'Start Date',
  endLabel: 'End Date',
}

export default DateRangeFilter
