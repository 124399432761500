import React, {
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { useTableColumnsPref, useCurrent, useQueryParams } from 'hooks'
import { ExportFormatTypes } from 'constants/index'
import config from 'config'
import { getClientFilterUI } from 'apis'
import { parseISO } from 'date-fns'
import { compose, withAuthorize, withReactQuery } from 'hocs'
import View from './View'
import useImportVendors from './useImportVendors'
import useImportCustomFields from './useImportCustomFields'
import useVendorsFetcher from './useVendorsFetcher'
import {
  getVendorsQueryParams,
  getExportUrl,
  getCSVContactExportUrl,
} from './utils'
import { ActionMenuItemTypes, RequestOpts, COL_NAMES } from './constants'

const Container = forwardRef(
  ({ orgId, canAdmin, requests, disabledIntegrations, userPrefs }, ref) => {
    const [columnToggles, setColumnToggles] = useTableColumnsPref(
      userPrefs,
      `table_columns.${RequestOpts[requests].prefsPath}`,
      COL_NAMES,
      orgId,
    )
    const importVendors = useImportVendors()
    const importCustomFields = useImportCustomFields()

    const [filterConfigList, setFilterConfigList] = useState([])
    const [
      { page, sizePerPage, sortField, sortOrder, searchText, includeArchived },
      setQueryParams,
    ] = useQueryParams({
      page: {
        type: Number,
        defaultValue: 1,
      },
      sizePerPage: {
        queryKey: 'per_page',
        type: Number,
        defaultValue: config.collectionQueryPageLimit.default,
      },
      sortField: {
        queryKey: 'ordered_by',
      },
      sortOrder: {
        queryKey: 'direction',
      },
      searchText: {
        queryKey: 'q',
        defaultValue: '',
      },
      includeArchived: {
        queryKey: 'include_archived',
        type: Boolean,
        defaultValue: false,
      },
    })
    const [filter, setFilter] = useState()

    const tableState = {
      page,
      per_page: sizePerPage,
      ordered_by: sortField,
      direction: sortOrder,
      search_query: searchText,
      includeArchived,
      filter,
    }

    const vendorsQueryParams = getVendorsQueryParams(
      tableState,
      requests,
      filterConfigList,
    )
    const vendorsQueryParamsRef = useCurrent(vendorsQueryParams)
    const {
      data: vendors,
      totalSize,
      isFetching: isFetchingVendors,
    } = useVendorsFetcher(vendorsQueryParams)

    const handleSearchChange = useCallback(
      (newValue) => {
        setQueryParams({
          page: 1,
          searchText: newValue,
        })
      },
      [setQueryParams],
    )

    const handleToggleArchived = useCallback(
      (event) => {
        setQueryParams({
          page: 1,
          includeArchived: event.target.checked,
        })
      },
      [setQueryParams],
    )

    const handleFilterChange = useCallback(
      (newValue) => {
        setQueryParams({ page: 1 })
        setFilter(newValue)
      },
      [setQueryParams],
    )

    const handleMenuItemSelect = useCallback(
      (key) => {
        switch (key) {
          case ActionMenuItemTypes.IMPORT_VENDORS:
            importVendors(orgId)
            return
          case ActionMenuItemTypes.IMPORT_CUSTOM_FIELDS:
            importCustomFields(orgId)
            return
          case ActionMenuItemTypes.EXPORT_CSV:
            window.location.assign(
              getExportUrl(
                ExportFormatTypes.CSV,
                vendorsQueryParamsRef.current,
              ),
            )
            return
          case ActionMenuItemTypes.EXPORT_XLS:
            window.location.assign(
              getExportUrl(
                ExportFormatTypes.XLSX,
                vendorsQueryParamsRef.current,
              ),
            )
            return
          case ActionMenuItemTypes.EXPORT_CONTACTS_CSV:
            window.location.assign(
              getCSVContactExportUrl(vendorsQueryParamsRef.current),
            )
            break
          default:
        }
      },
      [orgId, importVendors, importCustomFields, vendorsQueryParamsRef],
    )

    useImperativeHandle(ref, () => ({
      updateSearch: handleSearchChange,
    }))

    const [initialVisibleFilters, setInitialVisibleFilters] = useState([])
    const [filtersEditable, setFiltersEditable] = useState(false)
    useEffect(() => {
      const getFilterUI = async () => {
        const { data: clientFilterUI } = await getClientFilterUI({
          params: {
            filtering_for: 'vendor_list_view',
          },
        })
        const filteredList = clientFilterUI.filterConfigList.filter(
          (f) =>
            f.type === 'date_range' ||
            f.type === 'boolean' ||
            f.type === 'float' ||
            f.type === 'datetime_range' ||
            (f.options && f.options.length > 0),
        )
        const correctDates = filteredList.map((f) => {
          if (f.type === 'date_range' || f.type === 'datetime_range') {
            return {
              ...f,
              maxDate: parseISO(f.maxDate),
              minDate: parseISO(f.minDate),
            }
          }
          return f
        })
        setInitialVisibleFilters(clientFilterUI.initialVisibleFilters)
        setFilterConfigList(correctDates)
        setFiltersEditable(clientFilterUI.filtersEditable)
      }
      getFilterUI()
    }, [])

    return (
      <View
        {...tableState}
        vendors={vendors}
        totalSize={totalSize}
        loading={isFetchingVendors}
        columnToggles={columnToggles}
        requests={requests}
        disabledIntegrations={disabledIntegrations}
        filterConfigList={filterConfigList}
        initialVisibleFilters={initialVisibleFilters}
        filtersEditable={filtersEditable}
        canBulkImport={canAdmin}
        onPaginationChange={setQueryParams}
        onSortingChange={setQueryParams}
        onSearchChange={handleSearchChange}
        onMenuItemSelect={handleMenuItemSelect}
        onToggleArchived={handleToggleArchived}
        onFilterChange={handleFilterChange}
        onColumnTogglesChange={setColumnToggles}
      />
    )
  },
)

Container.propTypes = {
  orgId: PropTypes.string.isRequired,
  canAdmin: PropTypes.bool,
  requests: PropTypes.bool,
  disabledIntegrations: PropTypes.array,
  userPrefs: PropTypes.object,
}

export default React.memo(compose(withAuthorize(), withReactQuery())(Container))
