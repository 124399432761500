import styled from 'styled-components'
import A from 'components-v2/atoms/A'
import Button from 'components-v2/atoms/Button'
import IconButton from 'components-v2/atoms/IconButton'
import { mixins, colors } from 'styles'

const COL_WIDTH = 220
const ADD_FILTER_COL_WIDTH = 75
const GUTTER = 15
const FILTERBOX_BORDER = '#CCC'

export const Wrapper = styled.div`
  padding: 25px;
`

const calculateFilterRowWidth = (cols, addFilterButtonVisible, maxWidth) => {
  if (
    cols * (COL_WIDTH + GUTTER) +
      (addFilterButtonVisible ? ADD_FILTER_COL_WIDTH + GUTTER : 0) <=
    maxWidth
  ) {
    return (
      cols * (COL_WIDTH + GUTTER) +
      (addFilterButtonVisible ? ADD_FILTER_COL_WIDTH + GUTTER : 0)
    )
  }
  if (cols * (COL_WIDTH + GUTTER) <= maxWidth) {
    return cols * (COL_WIDTH + GUTTER)
  }
  return Math.floor(maxWidth / (COL_WIDTH + GUTTER)) * (COL_WIDTH + GUTTER)
}

export const FilterRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -${GUTTER / 2}px;

  ${({ $cols, addFilterButtonVisible, $maxWidth }) =>
    `width: ${calculateFilterRowWidth(
      $cols,
      addFilterButtonVisible,
      $maxWidth,
    )}px;`}
`

export const FilterCol = styled.div`
  width: ${COL_WIDTH}px;
  margin: 0 ${GUTTER / 2}px ${GUTTER}px;
`

export const FilterTitle = styled.div`
  ${mixins.smallHeader}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const FilterBox = styled.div`
  margin-top: 8px;
  border: 1px solid ${FILTERBOX_BORDER};
  border-radius: 4px;
  color: ${colors.DARK_GREY};
  font-weight: 500;
`

export const FilterBoxBody = styled.div`
  padding: 10px;
  overflow-y: auto;
  height: 166px;
`

export const FilterBoxFooter = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border-top: 1px solid ${FILTERBOX_BORDER};
`

export const SelectedFilterCount = styled.span`
  color: ${(props) => (props.value ? colors.PRIMARY : '#B5B5B5')};
  margin-right: auto;
`

export const ResetFilterLink = styled(A).attrs({ underline: true })`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.DARK_GREY};
`

export const RemoveFilterButton = styled(IconButton).attrs({
  icon: 'fa fa-times-circle',
})`
  margin-left: 6px;
  font-size: 17px;
`

export const AddFilterPopoverButton = styled(Button)`
  margin: 25px 7.5px 15px;
  width: ${ADD_FILTER_COL_WIDTH}px;
  height: 207px;
`

export const AddFilterPopoverContent = styled.div`
  padding: 9px 14px;
`

export const Footer = styled.div`
  text-align: right;
`

export const ApplyButton = styled(Button).attrs({
  color: 'primary',
  size: 'small',
})`
  margin-left: 10px;
`
