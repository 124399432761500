import pick from 'lodash/pick'
import { getPageUrl } from 'utils/url'
import { localTimeZoneOffsetInHours, convertUtcToLocalIso } from 'utils/date'
import { FilterTypes } from 'components-v2/molecules/FilterDropdownTray'
import { LabelTypes, RiskTierOptions, ExportFormatTypes } from 'constants/index'
import { RequestStatuses } from './constants'

export const getFilterConfigList = (requests, filterData) =>
  requests ? getRequestFilters(filterData) : getVendorFilters(filterData)

const getVendorFilters = (vendorTags = []) => {
  const filterConfigList = []
  if (vendorTags && vendorTags.length > 0) {
    filterConfigList.push({
      name: 'vendorTags',
      title: `${LabelTypes.VENDOR} Label`,
      options: vendorTags.map((tag) => ({
        value: tag.id,
        label: tag.name,
      })),
      selectAllVisible: true,
      matchVisible: true,
    })
  }
  return filterConfigList.concat([
    {
      name: 'risk_tier',
      title: `${LabelTypes.VENDOR} Risk Tier`,
      type: FilterTypes.MULTI_SELECT,
      options: [...RiskTierOptions, { value: 'none', label: 'None' }],
      selectAllVisible: true,
    },
    {
      name: 'createdAt',
      title: 'Created',
      type: FilterTypes.DATE_RANGE,
      minDate: new Date('2018-01-01'),
      maxDate: new Date(),
      datePickerProps: {
        dateFormat: 'MM/dd/yyyy',
      },
    },
    {
      name: 'nextReassessmentAt',
      title: 'Next Assessment',
      type: FilterTypes.DATE_RANGE,
      minDate: new Date('2018-01-01'),
      maxDate: new Date(),
      datePickerProps: {
        dateFormat: 'MM/dd/yyyy',
      },
    },
  ])
}

const getRequestFilters = (requesters = []) => {
  const filterConfigList = [
    {
      name: 'requestedBy',
      title: 'Requested By',
      options: requesters.map((user) => ({
        value: user.id,
        label: user.name,
      })),
    },
    {
      name: 'status',
      title: 'Status',
      options: Object.entries(RequestStatuses).map(([k, v]) => ({
        value: k,
        label: v,
      })),
    },
    {
      name: 'createdAt',
      title: 'Created',
      type: FilterTypes.DATE_RANGE,
      minDate: new Date('2018-01-01'),
      maxDate: new Date(),
      datePickerProps: {
        dateFormat: 'MM/dd/yyyy',
      },
    },
  ]
  return filterConfigList
}

export const getVendorsQueryParams = (
  { page, per_page, ordered_by, direction, search_query, filter },
  requests,
  filterConfigList,
) => {
  const handleMultiSelect = (value) =>
    value?.selected?.length > 0 ? value.selected : null

  const handleDateRange = (value) => {
    const { startDate, endDate } = value || {}
    if (!startDate && !endDate) return null

    return {
      min:
        startDate &&
        convertUtcToLocalIso(startDate, localTimeZoneOffsetInHours),
      max: endDate && convertUtcToLocalIso(endDate, localTimeZoneOffsetInHours),
    }
  }

  const handleBoolean = (value) => value?.selected || null

  const handleNumberRange = (value) => {
    const { min, max } = value || {}
    if (!min && !max) return null

    return {
      min: min || null,
      max: max || null,
    }
  }

  const handleVendorTags = (value) => {
    if (!value) return null
    return value.selected.join(',')
  }

  const filterHandlers = {
    [FilterTypes.MULTI_SELECT]: handleMultiSelect,
    [FilterTypes.DATE_RANGE]: handleDateRange,
    [FilterTypes.DATETIME_RANGE]: handleDateRange,
    [FilterTypes.BOOLEAN]: handleBoolean,
    [FilterTypes.NUMBER]: handleNumberRange,
    default: handleVendorTags,
  }

  /**
   * Generates a new parameters object based on the filter and filter configurations.
   *
   * @param {Object} filter - The filter object containing various filter criteria.
   * @param {Array} FCList - The list of filter configurations.
   * @returns {Object} - A new parameters object to be spread into the main params.
   */
  const generateParamsByFilterType = (filters, FCList) =>
    filters &&
    Object.keys(filters).reduce((acc, key) => {
      const filterConfig = FCList.find((f) => f.name === key)
      if (!FCList) return acc

      const handler =
        filterHandlers[filterConfig?.type] || filterHandlers.default
      const value = handler(filter[key])
      const any = filter[key]?.anyMatch
      return value ? { ...acc, [key]: value, any } : acc
    }, {})

  const params = {
    page,
    per_page,
    ordered_by,
    direction,
    search_query,
    requests,
    tz: localTimeZoneOffsetInHours,
    ...generateParamsByFilterType(filter, filterConfigList),
  }

  return params
}

export const getExportUrl = (format, queryParams) =>
  getPageUrl(
    queryParams.requests ? 'clientVendorRequestsExport' : 'clientVendorsExport',
    undefined,
    pick(queryParams, [
      'requests',
      'exclude_archived',
      'search_query',
      'with_vendor_tags',
      'any',
      'risk_tier_list',
      'requested_by',
      'status_list',
      'created_date',
      'next_date',
      'tz',
    ]),
    format,
  )

export const getCSVContactExportUrl = (queryParams) =>
  getPageUrl(
    'clientVendorsContactsExport',
    undefined,
    pick(queryParams, [
      'exclude_archived',
      'search_query',
      'with_vendor_tags',
      'any',
      'risk_tier_list',
      'created_date',
      'next_date',
      'create_start_date',
      'create_end_date',
      'next_start_date',
      'next_end_date',
      'tz',
    ]),
    ExportFormatTypes.CSV,
  )
